import axiosAPI from "./axiosInstance";

// Check results by ID
const checkResultsById = async (cartodb_id) => {
  try {
    const response = await axiosAPI.get(`/checkresult/${cartodb_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching result by ID:", error);
    throw error;
  }
};

// Create a new project
const createProject = async (projectData) => {
  try {
    const response = await axiosAPI.post('/project', projectData);
    return response.data;
  } catch (error) {
    console.error("Error creating project:", error);
    throw error;
  }
};

// Create a new shape
const createShape = async (shapeData) => {
  try {
    const response = await axiosAPI.post('/project/shape', shapeData);
    return response.data;
  } catch (error) {
    console.error("Error creating shape:", error);
    throw error;
  }
};

// Delete a project
const deleteProject = async (cartodb_id) => {
  try {
    const response = await axiosAPI.delete(`/project/${cartodb_id}`, {
    });
    return response.data; 
  } catch (error) {
    console.error("Error deleting project:", error);
    throw error;
  }
};

// Delete a shape
const deleteShape = async (shape_id) => {
  try {
    const response = await axiosAPI.delete(`/project/shape/${shape_id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting shape:", error);
    throw error;
  }
};

// Fetch all projects
const fetchProjects = async () => {
  try {
    const response = await axiosAPI.get(`/projects`, {
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};

// Get all shapes
const getAllShapes = async () => {
  try {
    const response = await axiosAPI.get(`/projects/shapes`);
    return response.data;
  } catch (error) {
    console.error("Error fetching shapes:", error);
    throw error;
  }
};

// Get buffering by ID
const getBufferringById = async (cartodb_id) => {
  try {
    const response = await axiosAPI.get(`/bufferring/${cartodb_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching ring by ID:", error);
    throw error;
  }
};

// Get buffering by user
const getBufferringByUser = async () => {
  try {
    const response = await axiosAPI.get(`/bufferring`);
    return response.data;
  } catch (error) {
    console.error("Error fetching ring by user:", error);
    throw error;
  }
};

// Get project by ID
const getProjectById = async (cartodb_id) => {
  try {
    const response = await axiosAPI.get(`/project/${cartodb_id}`, {
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching project by ID:", error);
    throw error;
  }
};

// Get results by ID
const getResultsById = async (cartodb_id) => {
  try {
    const response = await axiosAPI.get(`/result/${cartodb_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching result by ID:", error);
    throw error;
  }
};

// Get shapes by project ID
const getShapesByProjectId = async (shape_id) => {
  try {    
    const response = await axiosAPI.get(`/project/shape/${shape_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching shapes by project ID:", error);
    throw error;
  }
};

// Initiate modeling
const initiateModeling = async (project_id, models) => {
  try {
    const response = await axiosAPI.post(`/project/batch/${project_id}`, { models });
    return response;
  } catch (error) {
    console.error("Error initiating modeling for project ID:", project_id, error);
    throw error;
  }
};

// Update a project
const updateProject = async (cartodb_id, projectData) => {
  try {
    const response = await axiosAPI.put(`/project/${cartodb_id}`, projectData, {
    });
    return response.data;
  } catch (error) {
    console.error("Error updating project:", error);
    throw error;
  }
};

// Update a shape
const updateShape = async (shape_id, shapeData) => {
  try {
    const response = await axiosAPI.put(`/project/shape/${shape_id}`, shapeData);
    return response.data;
  } catch (error) {
    console.error("Error updating shape:", error);
    throw error;
  }
};

const downloadProjectsData = async (download_type) => {
  try {
    const response = await axiosAPI.post(`/projects/download/${download_type}`);
    return response.data;
  } catch (error) {
    console.error("Error downloading projects data:", error);
    throw error;
  }
};

export {
  checkResultsById, 
  createProject, 
  createShape, 
  deleteProject, 
  deleteShape, 
  fetchProjects,
  getAllShapes, 
  getBufferringById, 
  getBufferringByUser, 
  getProjectById, 
  getResultsById,
  getShapesByProjectId, 
  initiateModeling, 
  updateProject, 
  updateShape,
  downloadProjectsData
};
