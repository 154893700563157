/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { socket } from "../redux/socketSlice"; // Import socket directly
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { CircularProgress, Box, Snackbar, Alert } from "@mui/material";
import LegendControl from "./LegendControl";
import LandcoverPopup from "./LandcoverPopup";
import Overlay from "./Overlay";
import {
    createShape,
    updateShape,
    deleteShape,
    getBufferringById,
    getBufferringByUser,
    getResultsById,
} from "../api/project";
import { useBasemap } from "../BaseMapProvider";
import { useTranslation } from "react-i18next";
import { polygonOptions } from "../resources/projectFormOptions";
import { lineColors } from "../resources/mapLayerConfig";
import {
    setCurrentProjectId,
    setShapeStatus,
} from "../redux/projectSlice";
import {
    addShapesToMap,
    addResultsToMap,
    panToProjectResults,
    updateMapWithBufferingData,
    updateOutlineLayer,
} from "../utils/mapUtils";
import basemapStyles from "../resources/baseMapConfig";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapComponent = ({
    center = [139.6917, 35.6895],
    zoom = 12,
    highResolution = false,
    drawTools = true,
    overlay = false,
    main = false,
    collapsed = false,
}) => {
    const { basemap } = useBasemap();
    const mapContainer = useRef(null);
    const mapInstance = useRef(null);
    const drawInstance = useRef(null);
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const { t } = useTranslation();
    const isDarkMode = localStorage.getItem("theme") === "dark";
    const [bufferingData, setBufferingData] = useState([]);
    const [shizendoMax, setShizendoMax] = useState();
    const [sliderValue, setSliderValue] = useState([1, shizendoMax]);
    const [newShape, setNewShape] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [overlayOpen, setOverlayOpen] = useState(true);
    const [triggerReload, setTriggerReload] = useState(false);
    const projectStatus = useSelector((state) => state.project.projectStatus);
    const currentProjectId = useSelector((state) => state.project.currentProjectId);
    const isModeling = useSelector((state) => state.project.isModeling);
    const currentModelingProjectId = useSelector((state) => state.project.currentModelingProjectId);
    const mapState = useRef({ center: center, zoom: zoom });
    // error state
    const [error, setError] = useState(null);

    // Fetch buffering data for the current user
    const fetchBufferingData = useCallback(async () => {
        if (main) {
            try {
                setLoading(true);
                const response = await getBufferringByUser();
                if (response.length > 0) {
                    const newData = response.reduce((acc, item) => {
                        acc[item.project_id] = item;
                        return acc;
                    }, {});
                    setBufferingData(newData);
                    updateMapWithBufferingData(mapInstance.current, newData, lineColors, dispatch, setCurrentProjectId);
                } else {
                    setBufferingData({});
                }
            } catch (error) {
                console.error("Failed to fetch buffering data:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
    }, [dispatch, main]);

    const fetchResults = useCallback(async () => {
        if (overlay) {
            try {
                setLoading(true);
                const results = await getResultsById(projectId);
                const bufferingData = await getBufferringById(projectId);
                setBufferingData(bufferingData);
                updateOutlineLayer(mapInstance, bufferingData, lineColors);
                const maxShizendo = Math.max(...results.map((r) => r.shizendo));
                setShizendoMax(maxShizendo);
                setSliderValue([1, maxShizendo]);
                addResultsToMap(mapInstance, results, lineColors, t);
                await addShapesToMap(mapInstance.current, drawInstance, projectId, center, zoom, 2, overlay, setError);
            } catch (err) {
                console.error("Error fetching results:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
    }, [overlay, projectId, center, zoom, t]);

    const filterPolygonsByShizendo = useCallback(
        (range) => {
            if (mapInstance.current) {
                if (range[0] === 1 && range[1] === shizendoMax) {
                    mapInstance.current.setFilter("results-fill", null);
                } else {
                    mapInstance.current.setFilter("results-fill", [
                        "all",
                        [">=", ["get", "shizendo"], range[0]],
                        ["<=", ["get", "shizendo"], range[1]],
                    ]);
                }
            }
        },
        [shizendoMax]
    );

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        filterPolygonsByShizendo(newValue);
    };

    const handleClearFilters = () => {
        setSliderValue([1, shizendoMax]);
        filterPolygonsByShizendo([1, shizendoMax]);
    };

    const handlePopupClose = () => {
        setPopupOpen(false);
        if (newShape && !newShape.properties.id) {
            drawInstance.current.delete([newShape.id]);
            setNewShape(null);
        }
    };

    const handleDrawUpdate = useCallback(async (e) => {
        setLoading(true);
        const updatedData = {
            geom: e.features[0].geometry,
            landcover_type: e.features[0].properties.landcover_type,
        };
        const shapeId = e.features[0].properties.id;
        if (shapeId) {
            await updateShape(shapeId, updatedData);
            const source = mapInstance.current.getSource("shapes");
            if (source) {
                const data = source._data;
                const featureIndex = data.features.findIndex((f) => f.properties.id === shapeId);
                if (featureIndex > -1) {
                    data.features[featureIndex].geometry = updatedData.geom;
                    data.features[featureIndex].properties.landcover_type = updatedData.landcover_type;
                    source.setData(data);
                }
            }
            setLoading(false);
            showSnackbar(t("app.shapeUpdatedMessage"));
        }
    }, [t]);

    const handleDrawDelete = useCallback(async (e) => {
        setLoading(true);
        const shapeId = e.features[0].properties.id;
        if (shapeId) {
            await deleteShape(shapeId);
            const source = mapInstance.current.getSource("shapes");
            if (source) {
                const data = source._data;
                const updatedFeatures = data.features.filter((f) => f.properties.id !== shapeId);
                source.setData({ ...data, features: updatedFeatures });
            }
            setLoading(false);
            showSnackbar(t("app.shapeDeletedMessage"));
            dispatch(setShapeStatus(true));
        }
    }, [dispatch, t]);

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        // const socket = io(`${process.env.REACT_APP_UE_SOCKET_PORT}`);
        if (isModeling && currentModelingProjectId) {
            socket.emit("setStatus", { projectId: currentModelingProjectId, status: "running" });
        }
        socket.on("jobStatus", (data) => {
            if (data.status === "success") {
                setTriggerReload(true);
            }
        });
        return () => {
            socket.off("jobStatus");
            // socket.disconnect();
        };
    }, [isModeling, currentModelingProjectId]);

    useEffect(() => {
        if (!mapInstance.current) {
            // Check if basemap is a Mapbox style URL
            const isMapboxStyle = basemap.startsWith('mapbox://styles/');

            const mapConfig = {
                container: mapContainer.current,
                center: mapState.current.center, // Use saved center
                zoom: mapState.current.zoom,     // Use saved zoom
                minZoom: 5,
                tileSize: highResolution ? 512 : 256,
                attributionControl: false
            };

            if (isMapboxStyle) {
                mapConfig.style = basemap;
            } else {
                const tileUrl = Array.isArray(basemap) ? basemap : [basemap];
                mapConfig.style = {
                    version: 8,
                    sources: {
                        'custom-tiles': {
                            type: 'raster',
                            tiles: tileUrl,
                            tileSize: 256,
                            attribution: basemap?.attribution || '',
                        },
                    },
                    layers: [
                        {
                            id: 'custom-layer',
                            type: 'raster',
                            source: 'custom-tiles',
                            minzoom: 0,
                            maxzoom: 22,
                        },
                    ],
                };
            }

            // Initialize the map
            mapInstance.current = new mapboxgl.Map(mapConfig);
            mapInstance.current.addControl(new mapboxgl.NavigationControl(), "bottom-left");
            
            //setting attribute for different basemaps
            const basemapData = basemapStyles.find((style) => style.value === basemap);

                mapInstance.current.addControl(new mapboxgl.AttributionControl({
                customAttribution: basemapData.attribution
            }));

            // Restore map state when basemap changes
            mapInstance.current.once('style.load', () => {
                mapInstance.current.flyTo({
                    center: mapState.current.center,
                    zoom: mapState.current.zoom,
                });
            });
        }

        return () => {
            if (mapInstance.current) {
                mapState.current.center = mapInstance.current.getCenter().toArray();
                mapState.current.zoom = mapInstance.current.getZoom(); // Save the map state before removing
                mapInstance.current.remove();
                mapInstance.current = null;
            }
        };
    }, [basemap, center, zoom, highResolution])

    useEffect(() => {
        if (projectId && drawTools) {
            drawInstance.current = new MapboxDraw({
                displayControlsDefault: false,
                controls: { polygon: true, trash: true },
            });

            const drawControlContainer = document.createElement('div');
            drawControlContainer.className = 'mapbox-gl-draw_ctrl-draw-heading';
            drawControlContainer.textContent = t("app.drawTool");

            mapInstance.current.addControl({
                onAdd: function () {
                    return drawControlContainer;
                },
                onRemove: function () { }
            }, 'top-right');

            mapInstance.current.addControl(drawInstance.current);
            mapInstance.current.addControl(new LegendControl(polygonOptions, t), "bottom-right");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, basemap, t, zoom, center])

    useEffect(() => {
        // Ensure mapInstance is available before attaching event listeners
        if (!mapInstance.current) {
            console.warn("mapInstance is not initialized. Event listeners cannot be added.");
            return;
        }
        // Attach event listeners
        mapInstance.current.on("draw.create", (e) => {
            if (e?.features?.[0]) {
                setNewShape(e.features[0]);
                setPopupOpen(true);
            }
        });
        mapInstance.current.on("draw.update", handleDrawUpdate);
        mapInstance.current.on("draw.delete", handleDrawDelete);
        // Cleanup event listeners on unmount
        return () => {
            if (mapInstance.current) {
                mapInstance.current.off("draw.create");
                mapInstance.current.off("draw.update");
                mapInstance.current.off("draw.delete");
            }
        };
    }, [handleDrawUpdate, handleDrawDelete, projectId, basemap]);

    useEffect(() => {
        // Ensure mapInstance is initialized
        if (!mapInstance.current) {
            console.warn("mapInstance is not initialized. Cannot attach 'load' event listener.");
            return;
        }
        // Define the handler for the 'load' event
        const handleLoad = () => {
            if (projectId) {
                try {
                    addShapesToMap(mapInstance.current, drawInstance, projectId, center, zoom, 2, overlay, setError);
                } catch (error) {
                    console.error("Error adding shapes to the map:", error);
                }
            }
            // No projectId provided. Skipping addShapesToMap.
        };
        // Attach the 'load' event listener
        mapInstance.current.on("load", handleLoad);
        // Cleanup event listener on unmount
        return () => {
            if (mapInstance.current) {
                mapInstance.current.off("load", handleLoad);
            }
        };
    }, [center, overlay, projectId, zoom, basemap]);

    useEffect(() => {
        if (main) fetchBufferingData();
    }, [main, basemap, projectStatus, triggerReload, collapsed, fetchBufferingData, isDarkMode, t]);

    useEffect(() => {
        if (overlay) fetchResults();
    }, [overlay, fetchResults, basemap]);

    useEffect(() => {
        if (currentProjectId) {
            panToProjectResults(mapInstance, currentProjectId, center, zoom, showSnackbar, t);
        } else {
            mapInstance.current.flyTo({ center, zoom });
        }
    }, [currentProjectId, center, zoom, t]);

    // throwing error so that error boundary handle it
    if (error && error.includes('Network Error')) {
        throw error;
    }

    return (
        <>
            {loading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <div
                ref={mapContainer}
                style={{
                    width: "100%",
                    height: "100%",
                    filter: loading ? "blur(2px)" : "none",
                    transition: "filter 0.3s ease",
                }}
            />
            {popupOpen && (
                <LandcoverPopup
                    open={popupOpen}
                    onClose={handlePopupClose}
                    onSave={async (landcoverType) => {
                        setLoading(true);
                        if (newShape) {
                            const newShapeData = {
                                project_id: projectId,
                                geom: newShape.geometry,
                                landcover_type: landcoverType,
                            };
                            const savedShape = await createShape(newShapeData);
                            newShape.properties.id = savedShape.shape_id;
                            newShape.properties.landcover_type = landcoverType;
                            drawInstance.current.add(newShape);
                            const source = mapInstance.current.getSource("shapes");
                            if (source) {
                                const data = source._data;
                                data.features.push(newShape);
                                source.setData(data);
                            }
                            setNewShape(null);
                            setPopupOpen(false);
                            setLoading(false);
                            showSnackbar(t("app.shapeCreateMessage"));
                            dispatch(setShapeStatus(true));
                        }
                    }}
                />
            )}
            {overlay && (
                <Overlay
                    open={overlayOpen}
                    onToggle={() => setOverlayOpen(!overlayOpen)}
                    shizendo_max={shizendoMax}
                    sliderValue={sliderValue}
                    onSliderChange={handleSliderChange}
                    onClearFilters={handleClearFilters}
                    bufferingData={bufferingData}
                />
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    sx={{
                        width: "100%",
                        backgroundColor: "primary.main",
                        color: "primary.contrastText",
                        "& .MuiAlert-icon": {
                            color: "primary.contrastText",
                        },
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default MapComponent;
