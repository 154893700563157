import React from 'react';
import {
    IconButton,
    Popover,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Avatar,
    Typography,
    Box,
    Divider,
    Menu,
    MenuItem,
    CircularProgress
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LanguageIcon from '@mui/icons-material/Language';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LanguageSwitcher from './LanguageSwitcher';
import { styled } from '@mui/material/styles';
import { useThemeContext } from '../ThemeContext';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logout } from '../utils/authUtils/logout';
import { downloadProjectsData } from '../api/project';

const CustomPopover = styled(Popover)(({ theme }) => ({
    '& .MuiPopover-paper': {
        marginTop: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        minWidth: 240,
    },
}));

const UserArea = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
}));

const MinimalToggle = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 30,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    borderRadius: 15,
    position: 'relative',
    cursor: 'pointer',
    padding: '3px',
    boxShadow: theme.shadows[1],
}));

const ToggleButton = styled(({ isDarkMode, ...otherProps }) => <Box {...otherProps} />)(({ theme, isDarkMode }) => ({
    width: 24,
    height: 24,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    position: 'absolute',
    left: isDarkMode ? 'calc(100% - 24px)' : '3px',
    transition: 'left 0.3s ease',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
}));


const NavDrawer = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState('');
    const { toggleTheme } = useThemeContext();
    const { t } = useTranslation();
    const isDarkMode = localStorage.getItem('theme') === 'dark';
    const { signOut } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate();
    const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = React.useState(null);
    const [isDownloading, setIsDownloading] = React.useState(false);

    // Access username and email from Redux store
    const userData = useSelector((state) => state.auth.userData);

    const username = userData?.find(attr => attr.Name === "preferred_username")?.Value;
    const email = userData?.find(attr => attr.Name === "email")?.Value;

    // Access isAdmin from Redux store
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleListItemClick = (item) => {
        setSelectedItem(item);
        // Close the popover after selection
        handleClose();

        // Navigate based on the selected item
        switch (item) {
            case 'Home':
                // Update with the correct route for Home
                navigate('/');
                break;
            case 'Dashboard':
                // Update with the correct route for Dashboard
                navigate('/dashboard');
                break;
            case 'About Us':
                // Update with the correct route for About Us
                navigate('/about');
                break;
            case 'SignOut':
                logout(signOut);
                break;
            default:
                break;
        }
    };

    const handleToggleTheme = () => {
        toggleTheme();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleDownloadMenuOpen = (event) => {
        setDownloadMenuAnchorEl(event.currentTarget);
    };

    // Handle download menu close
    const handleDownloadMenuClose = () => {
        setDownloadMenuAnchorEl(null);
    };

    const handleProjectListDownload = async (download_type) => {
        try {
            if (isDownloading) return;
        
            setIsDownloading(true);
            // Fetch the data
            const response = await downloadProjectsData(download_type);

            // Check if the response contains the data
            if (!response?.body) {
                throw new Error('No data found in the response');
            }

            // Determine the file type and extension
            const fileType = download_type === 'csv' ? 'text/csv' : 'application/json';
            const fileExtension = download_type === 'csv' ? 'csv' : 'json';

            // Create a Blob from the data
            const blob = new Blob([response.body], { type: fileType });

            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `projects.${fileExtension}`;
            document.body.appendChild(a);

            // Trigger the download
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error(`Error downloading ${download_type.toUpperCase()}:`, error.message);
            // Optionally, show an error message to the user
        } finally {
            setIsDownloading(false);
            handleDownloadMenuClose();
        }
    };


    return (
        <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
                <MenuIcon />
            </IconButton>
            <CustomPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <UserArea>
                    <Avatar src="/path/to/your/avatar.jpg" sx={{ width: 56, height: 56, mb: 1 }} />
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{username}</Typography>
                    <Typography variant="body2" color="text.secondary">{email}</Typography>
                </UserArea>
                <List sx={{ py: 0 }}>
                    <ListItem button onClick={() => handleListItemClick('Home')} selected={selectedItem === 'Home'}>
                        <ListItemIcon><HomeIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary={t('app.home')} />
                    </ListItem>
                    {isAdmin && (
                        <ListItem button onClick={() => handleListItemClick('Dashboard')} selected={selectedItem === 'Dashboard'}>
                            <ListItemIcon><DashboardIcon fontSize="small" /></ListItemIcon>
                            <ListItemText primary={t('app.dahsboard')} />
                        </ListItem>
                    )}
                    {isAdmin && (
                        <ListItem 
                            button 
                            onClick={handleDownloadMenuOpen} 
                            selected={selectedItem === 'Download'}
                            disabled={isDownloading}
                        >
                            <ListItemIcon>
                                {isDownloading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    <DownloadIcon fontSize="medium" />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={t('app.downloadLabel')} />
                        </ListItem>
                    )}
                    {isAdmin && (
                        <Menu
                            anchorEl={downloadMenuAnchorEl}
                            open={Boolean(downloadMenuAnchorEl)}
                            onClose={handleDownloadMenuClose}
                        >
                            <MenuItem 
                                onClick={() => handleProjectListDownload('csv')}
                                disabled={isDownloading}
                            >
                                {isDownloading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CircularProgress size={20} sx={{ mr: 1 }} />
                                        {t('app.downloadLoadingText')}
                                    </Box>
                                ) : (
                                    t('app.csvDownloadLabel')
                                )}
                            </MenuItem>
                            {/* <MenuItem 
                                onClick={() => handleProjectListDownload('json')}
                                disabled={isDownloading}
                            >
                                {isDownloading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CircularProgress size={20} sx={{ mr: 1 }} />
                                        {t('app.downloading')}
                                    </Box>
                                ) : (
                                    t('app.jsonDownloadLabel')
                                )}
                            </MenuItem> */}
                        </Menu>
                    )}
                    <ListItem button onClick={() => handleListItemClick('About Us')} selected={selectedItem === 'About Us'}>
                        <ListItemIcon><InfoIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary={t('app.aboutUs')} />
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem>
                        <ListItemIcon><LanguageIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ mr: 2 }}>{t('app.language')}</Typography>
                                <LanguageSwitcher menuItem />
                            </Box>
                        } />
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem>
                        <ListItemIcon>
                            {isDarkMode ? <Brightness4Icon fontSize="small" /> : <Brightness7Icon fontSize="small" />}
                        </ListItemIcon>
                        <ListItemText primary={isDarkMode ? t('app.darkMode') : t('app.lightMode')} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 'auto' }}>
                            <MinimalToggle onClick={handleToggleTheme}>
                                <ToggleButton isDarkMode={isDarkMode}>
                                    {isDarkMode ? <Brightness4Icon fontSize="small" /> : <Brightness7Icon fontSize="small" />}
                                </ToggleButton>
                            </MinimalToggle>
                        </Box>
                    </ListItem>
                    <Divider sx={{ my: 1 }} />
                    <ListItem button onClick={() => handleListItemClick('SignOut')} selected={selectedItem === 'SignOut'}>
                        <ListItemIcon><ExitToAppIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary={t('app.signOut')} />
                    </ListItem>
                </List>
            </CustomPopover>
        </>
    );
};

export default NavDrawer;
