const basemapStyles = [
    {
        label: 'Street',
        value: 'mapbox://styles/mapbox/light-v11',
        translationKey: 'app.street',
        attribution: null
    },
    {
        label: 'Dark Mode',
        value: 'mapbox://styles/mapbox/dark-v10',
        translationKey: 'app.darkMode',
        attribution: null 
    },
    {
        label: 'Basic Map',
        value: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
        translationKey: 'app.basicMap',
        attribution: '出典：<a href="https://maps.gsi.go.jp/development/ichiran.html" target="_blank">国土地理院</a>'
    },
    {
        label: 'Aerial Photograph',
        value: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
        translationKey: 'app.aerialPhotograph',
        attribution: null
    },
    {
        label: 'Hillshade Map',
        value: 'https://cyberjapandata.gsi.go.jp/xyz/hillshademap/{z}/{x}/{y}.png',
        translationKey: 'app.hillshadeMap',
        attribution: null
    },
];

export default basemapStyles;